export const Roles = {
  SYSTEM_ADMIN: 1000,
  ADMIN: 2000,
  USER: 3000,
};

export const Permissions = {
  Vd: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    updateStatus: false,
    resetPassword: false,
    resendInvite: false,
    delete: false,
  },
  VdCf: {
    viewAll: false,
    viewOne: false,
    update: false,
  },
  VdUs: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    updateStatus: false,
    resetPassword: false,
    resendInvite: false,
    delete: false,
  },
  EeInc: {
    viewAll: false,
    viewOne: false,
    create: false,
    delete: false,
  },
  Cs: {
    viewAll: false,
    viewOne: false,
    invite: false,
    delete: false,
  },
  Cl: {
    viewAll: false,
    viewOne: false,
    join: false,
    delete: false,
  },
  Ms: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    updateStatus: false,
    delete: false,
  },
  MsRpt: {
    // Only 'all' permission key is used only. Other keys will be removed in future.
    userMonth: false,
    userMistake: false,
    mistakeMonth: false,
    all: false,
  },
  Pj: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    updateStatus: false,
    delete: false,
  },
  PjBch: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    updateStatus: false,
    delete: false,
  },
  PjPm: {
    viewAll: false,
    viewOne: false,
    update: false,
  },
  PjTkSts: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
  },
  PjTkStsPm: {
    viewAll: false,
    viewOne: false,
    update: false,
  },
  PjTkStsTrn: {
    viewAll: false,
    viewOne: false,
    update: false,
  },
  PjCs: {
    viewOne: false,
    assign: false,
  },
  PjLbl: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    delete: false,
  },
  PjUs: {
    updateManagers: false,
    viewAllManagers: false,
    updateDevelopers: false,
    viewAllDevelopers: false,
    updateCsUsers: false,
    viewAllCsUsers: false,
  },
  PjTk: {
    viewAll: false,
    create: false,
  },
  Tk: {
    viewAll: false,
    viewOne: false,
    create: false,
    updateStatus: false,
    delete: false,
  },
  TkDsc: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
  },
  TkFile: {
    viewAll: false,
    viewOne: false,
    create: false,
    delete: false,
  },
  TkCmt: {
    viewAll: false,
    viewOne: false,
    create: false,
    delete: false,
  },
  TkIns: {
    viewAll: false,
    updateInitial: false,
    updateFinal: false,
  },
  TkLbl: {
    update: false,
  },
  TkUs: {
    viewAll: false,
    updateDeveloper: false,
    updateReviewer: false,
  },
  TkSbt: {
    viewAll: false,
    viewOne: false,
    create: false,
    update: false,
    createDelay: false,
    delete: false,
  },
  TkSbtWk: {
    viewAll: false,
    create: false,
  },
  TkSbtDly: {
    viewAll: false,
    create: false,
  },
  Wk: {
    viewAll: false,
  },
  WrkRpt: {
    daily: false,
    minutes: false,
  },
};

/*
 If menu has submenu, then main menu path should be prefix of submenu path.
 For example: submenu path = '/word1/word2' then main menu path = '/word1'. 
 Follow example format to create path values.

 Note: To allow menu to all users, don't add "permissionKey" object
*/
export const Menus = [
  {
    id: "vendors",
    label: "Vendors",
    path: "/vendors",
    permissionKey: { module: "Vd", permission: "viewAll" },
  },
  {
    id: "users",
    label: "Users",
    path: "/users",
    permissionKey: { module: "VdUs", permission: "viewAll" },
  },
  {
    id: "clients",
    label: "Clients",
    path: "/clients",
    permissionKey: { module: "Cl", permission: "viewAll" },
  },
  {
    id: "customers",
    label: "Customers",
    path: "/customers",
    permissionKey: { module: "Cs", permission: "viewAll" },
  },
  {
    id: "projects",
    label: "Projects",
    path: "/projects",
    submenu: [
      {
        id: "projects",
        label: "Projects",
        path: "/projects",
      },
      {
        id: "projectBatches",
        label: "Batches",
        path: "/projects/batches",
        permissionKey: { module: "PjBch", permission: "viewAll" },
      },
    ],
  },
  {
    id: "tasks",
    label: "Tasks",
    path: "/tasks",
    submenu: [
      {
        id: "tasks",
        label: "Tasks",
        path: "/tasks",
      },
      {
        id: "allTasks",
        label: "All Tasks",
        path: "/tasks/all",
      },
    ],
  },
  {
    id: "works",
    label: "Works",
    path: "/works",
    permissionKey: { module: "Wk", permission: "viewAll" },
  },
  {
    id: "workReport",
    label: "Work Report",
    path: "/reports",
    submenu: [
      {
        id: "dailyWorkReport",
        label: "Daily Work",
        path: "/reports/daily-work",
        permissionKey: { module: "WrkRpt", permission: "daily" },
      },
      {
        id: "workHourReport",
        label: "Work Hour",
        path: "/reports/work-hour",
        permissionKey: { module: "WrkRpt", permission: "minutes" },
      },
      {
        id: "mistakesReport",
        label: "Mistakes",
        path: "/reports/mistakes",
        permissionKey: { module: "MsRpt", permission: "all" },
      },
    ],
  },
  {
    id: "mistakes",
    label: "Mistakes",
    path: "/mistakes",
    permissionKey: { module: "Ms", permission: "viewAll" },
  },
];

export const ActionType = {
  Create: 100,
  View: 200,
  Update: 300,
  Delete: 400,
  Assign: 500,
  Unassign: 600,
  Reassign: 700,
  Complete: 900,
};

export const PermKeySeparator = "-";
const PermType = {
  PjProject: 21000,
  PjBilling: 21100,
  PjStatus: 21200,
  PjCustomer: 21300,
  PjLabel: 21400,
  PjManager: 21500,
  PjDeveloper: 21600,
  PjCsUser: 21700,
  PjPermission: 21800,
  PjPmAccessRole: 21900,
  PjPmAlertRole: 22000,
  PjTkStatus: 22100,
  PjTkStsPermission: 22200,
  PjTkStsPmAccessRole: 22300,
  PjTkStsPmAlertRole: 22400,
  PjTkStsTransition: 22500,
  PjTkStsTrnAccessRole: 22600,
  PjTkStsTrnAlertRole: 22700,
  // Project Task
  PjTkTask: 31000,
  PjTkCrtDeveloper: 31100,
  PjTkCrtReviewer: 31200,
  PjTkCrtLabel: 31300,
  PjTkCrtHideFromCustomer: 31400,
  // Task
  TkDesc: 41000,
  TkLabel: 41100,
  TkFile: 41200,
  TkIntComment: 41300,
  TkComment: 41400,
  TkInitialIns: 41500,
  TkFinalIns: 41600,
  TkDeveloper: 41700,
  TkReviewer: 41800,
  TkSubTask: 41900,
  TkSubTaskWork: 42000,
  TkTaskLink: 42100,
  TkTaskMistake: 42200,

  // Task Status
  TkStsTask: 51000,
  TkStsStatus: 51100,
  TkStsSummary: 51200,
  TkStsDesc: 51300,
  TkStsLabel: 51400,
  TkStsFile: 51500,
  TkStsIntComment: 51600,
  TkStsComment: 51700,
  TkStsInitialIns: 51800,
  TkStsFinalIns: 51900,
  TkStsDeveloper: 52000,
  TkStsReviewer: 52100,
  TkStsSubTask: 52200,
  TkStsSubTaskWork: 52300,
  TkStsTaskLink: 52400,
  TkStsMistake: 52500,
};

export const ProjectPerm = {
  Update: `${PermType.PjProject}${PermKeySeparator}${ActionType.Update}`,
  Delete: `${PermType.PjProject}${PermKeySeparator}${ActionType.Delete}`,
  ViewBilling: `${PermType.PjBilling}${PermKeySeparator}${ActionType.View}`, // new
  UpdateBilling: `${PermType.PjBilling}${PermKeySeparator}${ActionType.Update}`, // new
  UpdateStatus: `${PermType.PjStatus}${PermKeySeparator}${ActionType.Update}`,
  ViewCustomer: `${PermType.PjCustomer}${PermKeySeparator}${ActionType.View}`,
  // UpdateCustomer: `${PermType.PjCustomer}${PermKeySeparator}${ActionType.Assign}`,
  AssignCustomer: `${PermType.PjCustomer}${PermKeySeparator}${ActionType.Assign}`,
  UnassignCustomer: `${PermType.PjCustomer}${PermKeySeparator}${ActionType.Unassign}`, // new
  ReassignCustomer: `${PermType.PjCustomer}${PermKeySeparator}${ActionType.Reassign}`, // new
  ViewLabels: `${PermType.PjLabel}${PermKeySeparator}${ActionType.View}`,
  CreateLabel: `${PermType.PjLabel}${PermKeySeparator}${ActionType.Create}`,
  UpdateLabel: `${PermType.PjLabel}${PermKeySeparator}${ActionType.Update}`,
  DeleteLabel: `${PermType.PjLabel}${PermKeySeparator}${ActionType.Delete}`,
  ViewManagers: `${PermType.PjManager}${PermKeySeparator}${ActionType.View}`,
  // UpdateManagers: `${PermType.PjManager}${PermKeySeparator}${ActionType.Assign}`,
  AssignManager: `${PermType.PjManager}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignManager: `${PermType.PjManager}${PermKeySeparator}${ActionType.Unassign}`, // new
  ReassignManager: `${PermType.PjManager}${PermKeySeparator}${ActionType.Reassign}`, // new
  ViewDevelopers: `${PermType.PjDeveloper}${PermKeySeparator}${ActionType.View}`,
  // UpdateDevelopers: `${PermType.PjDeveloper}${PermKeySeparator}${ActionType.Assign}`,
  AssignDevelopers: `${PermType.PjDeveloper}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignDevelopers: `${PermType.PjDeveloper}${PermKeySeparator}${ActionType.Unassign}`, // new
  ViewCustomerUsers: `${PermType.PjCsUser}${PermKeySeparator}${ActionType.View}`,
  // UpdateCustomerUsers: `${PermType.PjCsUser}${PermKeySeparator}${ActionType.Create}`,
  AssignCustomerUsers: `${PermType.PjCsUser}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignCustomerUsers: `${PermType.PjCsUser}${PermKeySeparator}${ActionType.Unassign}`, // new
  ViewPerms: `${PermType.PjPermission}${PermKeySeparator}${ActionType.View}`,
  // UpdatePerms: `${PermType.PjPermission}${PermKeySeparator}${ActionType.Create}`,
  AssignPmAccessRoles: `${PermType.PjPmAccessRole}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignPmAccessRoles: `${PermType.PjPmAccessRole}${PermKeySeparator}${ActionType.Unassign}`, // new
  AssignPmAlertRoles: `${PermType.PjPmAlertRole}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignPmAlertRoles: `${PermType.PjPmAlertRole}${PermKeySeparator}${ActionType.Unassign}`, // new
  ViewTkStatuses: `${PermType.PjTkStatus}${PermKeySeparator}${ActionType.View}`,
  CreateTkStatuses: `${PermType.PjTkStatus}${PermKeySeparator}${ActionType.Create}`,
  UpdateTkStatuses: `${PermType.PjTkStatus}${PermKeySeparator}${ActionType.Update}`,
  DeleteTkStatuses: `${PermType.PjTkStatus}${PermKeySeparator}${ActionType.Delete}`,
  ViewTkStsPerms: `${PermType.PjTkStsPermission}${PermKeySeparator}${ActionType.View}`,
  // UpdateTkStsPerms: `${PermType.PjProject}${PermKeySeparator}${ActionType.Create}`,
  AssignTkStsPmAccessRoles: `${PermType.PjTkStsPmAccessRole}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignTkStsPmAccessRoles: `${PermType.PjTkStsPmAccessRole}${PermKeySeparator}${ActionType.Unassign}`, // new
  AssignTkStsPmAlertRoles: `${PermType.PjTkStsPmAlertRole}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignTkStsPmAlertRoles: `${PermType.PjTkStsPmAlertRole}${PermKeySeparator}${ActionType.Unassign}`, // new
  ViewTkStsTransitions: `${PermType.PjTkStsTransition}${PermKeySeparator}${ActionType.View}`,
  // UpdateTkStsTransitions: `${PermType.PjProject}${PermKeySeparator}${ActionType.Create}`,
  AssignTkStsTrnAccessRoles: `${PermType.PjTkStsTrnAccessRole}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignTkStsTrnAccessRoles: `${PermType.PjTkStsTrnAccessRole}${PermKeySeparator}${ActionType.Unassign}`, // new
  AssignTkStsTrnAlertRoles: `${PermType.PjTkStsTrnAlertRole}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignTkStsTrnAlertRoles: `${PermType.PjTkStsTrnAlertRole}${PermKeySeparator}${ActionType.Unassign}`, // new
};

export const ProjectTkPerm = {
  CreateTask: `${PermType.PjTkTask}${PermKeySeparator}${ActionType.Create}`,
  AssignTaskDeveloper: `${PermType.PjTkCrtDeveloper}${PermKeySeparator}${ActionType.Assign}`,
  AssignTaskReviewer: `${PermType.PjTkCrtReviewer}${PermKeySeparator}${ActionType.Assign}`, // new
  AssignTaskLabels: `${PermType.PjTkCrtLabel}${PermKeySeparator}${ActionType.Assign}`, // new
  HideTaskFromCustomer: `${PermType.PjTkCrtHideFromCustomer}${PermKeySeparator}${ActionType.Update}`,
};

export const TaskPerm = {
  ViewDescriptions: `${PermType.TkDesc}${PermKeySeparator}${ActionType.View}`,
  ViewLabels: `${PermType.TkLabel}${PermKeySeparator}${ActionType.View}`, // new
  ViewFiles: `${PermType.TkFile}${PermKeySeparator}${ActionType.View}`,
  ViewIntComments: `${PermType.TkIntComment}${PermKeySeparator}${ActionType.View}`,
  ViewComments: `${PermType.TkComment}${PermKeySeparator}${ActionType.View}`,
  // ViewInstructions: `${PermType.PjProject}${PermKeySeparator}${ActionType.View}`,
  ViewInitialIns: `${PermType.TkInitialIns}${PermKeySeparator}${ActionType.View}`, // new
  ViewFinalIns: `${PermType.TkFinalIns}${PermKeySeparator}${ActionType.View}`, // new
  // ViewManagers: `${PermType.PjProject}${PermKeySeparator}${ActionType.View}`,
  ViewDevelopers: `${PermType.TkDeveloper}${PermKeySeparator}${ActionType.View}`,
  ViewReviewers: `${PermType.TkReviewer}${PermKeySeparator}${ActionType.View}`,
  ViewSubTasks: `${PermType.TkSubTask}${PermKeySeparator}${ActionType.View}`,
  // ViewSubTaskDelays: `${PermType.PjProject}${PermKeySeparator}${ActionType.View}`,
  ViewSubTaskWorks: `${PermType.TkSubTaskWork}${PermKeySeparator}${ActionType.View}`,
  ViewTaskLink: `${PermType.TkTaskLink}${PermKeySeparator}${ActionType.View}`,
  ViewTaskMistakes: `${PermType.TkTaskMistake}${PermKeySeparator}${ActionType.View}`,
};

export const TaskStsPerm = {
  Delete: `${PermType.TkStsTask}${PermKeySeparator}${ActionType.Delete}`,
  UpdateStatus: `${PermType.TkStsStatus}${PermKeySeparator}${ActionType.Update}`,
  UpdateSummary: `${PermType.TkStsSummary}${PermKeySeparator}${ActionType.Update}`,
  CreateDescription: `${PermType.TkStsDesc}${PermKeySeparator}${ActionType.Create}`,
  UpdateDescription: `${PermType.TkStsDesc}${PermKeySeparator}${ActionType.Update}`,
  DeleteDescription: `${PermType.TkStsDesc}${PermKeySeparator}${ActionType.Delete}`, // new
  // UpdateLabels: `${PermType.PjProject}${PermKeySeparator}${ActionType.Update}`,
  AssignLabels: `${PermType.TkStsLabel}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignLabels: `${PermType.TkStsLabel}${PermKeySeparator}${ActionType.Unassign}`, // new
  CreateFile: `${PermType.TkStsFile}${PermKeySeparator}${ActionType.Create}`,
  DeleteFile: `${PermType.TkStsFile}${PermKeySeparator}${ActionType.Delete}`,
  CreateIntComment: `${PermType.TkStsIntComment}${PermKeySeparator}${ActionType.Create}`,
  UpdateIntComment: `${PermType.TkStsIntComment}${PermKeySeparator}${ActionType.Update}`, // new
  DeleteIntComment: `${PermType.TkStsIntComment}${PermKeySeparator}${ActionType.Delete}`,
  CreateComment: `${PermType.TkStsComment}${PermKeySeparator}${ActionType.Create}`,
  UpdateComment: `${PermType.TkStsComment}${PermKeySeparator}${ActionType.Update}`, // new
  DeleteComment: `${PermType.TkStsComment}${PermKeySeparator}${ActionType.Delete}`,
  UpdateInitialIns: `${PermType.TkStsInitialIns}${PermKeySeparator}${ActionType.Update}`,
  CreateFinalIns: `${PermType.TkStsFinalIns}${PermKeySeparator}${ActionType.Create}`,
  UpdateFinalIns: `${PermType.TkStsFinalIns}${PermKeySeparator}${ActionType.Update}`,
  DeleteFinalIns: `${PermType.TkStsFinalIns}${PermKeySeparator}${ActionType.Delete}`,
  // UpdateDevelopers: `${PermType.PjProject}${PermKeySeparator}${ActionType.Update}`,
  AssignDeveloper: `${PermType.TkStsDeveloper}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignDeveloper: `${PermType.TkStsDeveloper}${PermKeySeparator}${ActionType.Unassign}`, // new
  ReassignDeveloper: `${PermType.TkStsDeveloper}${PermKeySeparator}${ActionType.Reassign}`, // new
  // UpdateReviewers: `${PermType.PjProject}${PermKeySeparator}${ActionType.Update}`,
  AssignReviewer: `${PermType.TkStsReviewer}${PermKeySeparator}${ActionType.Assign}`, // new
  UnassignReviewer: `${PermType.TkStsReviewer}${PermKeySeparator}${ActionType.Unassign}`, // new
  ReassignReviewer: `${PermType.TkStsReviewer}${PermKeySeparator}${ActionType.Reassign}`, // new
  CreateSubTask: `${PermType.TkStsSubTask}${PermKeySeparator}${ActionType.Create}`,
  UpdateSubTask: `${PermType.TkStsSubTask}${PermKeySeparator}${ActionType.Update}`,
  DeleteSubTask: `${PermType.TkStsSubTask}${PermKeySeparator}${ActionType.Delete}`,
  CreateSubTaskWork: `${PermType.TkStsSubTaskWork}${PermKeySeparator}${ActionType.Create}`,
  LinkTask: `${PermType.TkStsTaskLink}${PermKeySeparator}${ActionType.Assign}`,
  UnlinkTask: `${PermType.TkStsTaskLink}${PermKeySeparator}${ActionType.Unassign}`,
  CreateMistake: `${PermType.TkStsMistake}${PermKeySeparator}${ActionType.Create}`,
  DeleteMistake: `${PermType.TkStsMistake}${PermKeySeparator}${ActionType.Delete}`,
  CompleteMistake: `${PermType.TkStsMistake}${PermKeySeparator}${ActionType.Complete}`,
};

export const VendorStatus = {
  VENDOR_INVITED: 100,
  VENDOR_ACTIVE: 200,
  VENDOR_INACTIVE: 300,
};

export const ClientStatus = {
  CLIENT_INVITED: 100,
  CLIENT_ACTIVE: 200,
  CLIENT_INACTIVE: 300,
};

export const CustomerStatus = {
  CUSTOMER_INVITED: 100,
  CUSTOMER_ACTIVE: 200,
  CUSTOMER_INACTIVE: 300,
};

export const UserStatus = {
  USER_INVITED: 100,
  USER_ACTIVE: 200,
  USER_INACTIVE: 300,
};

export const ProjectStatus = {
  PROJECT_ACTIVE: 100,
  PROJECT_INACTIVE: 200,
  PROJECT_VIEW_ONLY: 300,
};

export const ProjectBatchStatus = {
  BATCH_PENDING: 100,
  BATCH_COMPLETED: 200,
};

export const MistakeStatus = {
  MISTAKE_ACTIVE: 100,
  MISTAKE_INACTIVE: 200,
};

export const IsJoinedFilterOptions = [
  { id: true, name: "YES" },
  { id: false, name: "NO" },
];

export const LocalDataKeys = {
  tasksFilter: "tasksFilter",
  tasksFilterCombinations: "tasksFilterCombinations",
  tkPwFilter: "tkPwFilter", // tk - Task, Pw - Project wise
  tkPwFilterCombinations: "tkPwFilterCombinations",
};

export const TaskActionTypes = {
  TK_ACT_CREATE: { id: 100, name: "Create", actionSentence: "added the" },
  TK_ACT_VIEW: { id: 200, name: "View", actionSentence: "viewed the" },
  TK_ACT_UPDATE: { id: 300, name: "Update", actionSentence: "updated the" },
  TK_ACT_DELETE: { id: 400, name: "Delete", actionSentence: "deleted the" },
  TK_ACT_ASSIGN: { id: 500, name: "Assign", actionSentence: "assigned the" },
  TK_ACT_UNASSIGN: { id: 600, name: "Unassign", actionSentence: "unassigned the" },
  TK_ACT_REASSIGN: { id: 700, name: "Reassign", actionSentence: "reassigned the" },
};
