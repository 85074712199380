import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { IoIosArrowDown, IoMdCloseCircle } from "react-icons/io";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";

import AppIcon from "Assets/icons/logo-100.png";
import { useAuth } from "../Contexts/AuthContext";
import ProfileImageBox from "./ProfileImageBox";
import { concatStrings, truncateString } from "../Utils/common";

const MenuListItem = ({ path, label }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <NavLink
      exact
      to={path}
      style={{
        display: "block",
        padding: "8px 16px",
        fontSize: "16px",
        backgroundColor: hovered ? "#9494941a" : "inherit",
      }}
      activeStyle={{
        backgroundColor: "#9494942a",
        fontWeight: "bold",
        color: "#00bfff",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {label}
    </NavLink>
  );
};
MenuListItem.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const DesktopMenuLink = ({ to, submenu, label, ...props }) => {
  const [showSubmenu, setShowSubmenu] = useState(false);

  return (
    <ListItem
      float="left"
      onMouseEnter={() => submenu && setShowSubmenu(true)}
      onMouseLeave={() => submenu && setShowSubmenu(false)}
    >
      <NavLink
        exact={!submenu}
        to={to}
        style={{
          display: "block",
          padding: "0 16px",
          lineHeight: "48px",
          fontSize: "16px",
          pointerEvents: !submenu ? "initial" : "none",
        }}
        activeStyle={{
          borderBottom: "3px solid #00bfff",
          fontWeight: "bold",
          color: "#00bfff",
          lineHeight: "45px",
        }}
        {...props}
      >
        <Flex alignItems="center" className="space-x-1">
          <Text>{label}</Text>
          {submenu ? <>{showSubmenu ? <AiFillCaretUp /> : <AiFillCaretDown />}</> : null}
        </Flex>
      </NavLink>
      {submenu ? (
        <UnorderedList
          listStyleType="none"
          position="absolute"
          top="48px"
          zIndex={455}
          bg="#fff"
          m={0}
          p="2px"
          display={showSubmenu ? "block" : "none"}
          boxShadow="0 0 5px 1px #4a4a4a1a"
        >
          {submenu.map((sm) => (
            <ListItem key={sm.id} onClick={() => setShowSubmenu(false)}>
              <MenuListItem path={sm.path} label={sm.label} />
            </ListItem>
          ))}
        </UnorderedList>
      ) : null}
    </ListItem>
  );
};
DesktopMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  submenu: PropTypes.array,
};

const MobileMenuLink = ({ to, submenu, label, handleMenuClose }) => {
  const haveSubmenu = !!submenu?.length;
  const isActive = useRouteMatch(to);
  const isSubmenuActive = haveSubmenu && !!isActive;
  const activeStyle = {
    fontWeight: "bold",
    color: "#00bfff",
  };

  return (
    <AccordionItem py={1} mx={-2}>
      <AccordionButton
        as={!haveSubmenu ? NavLink : ""}
        {...(!haveSubmenu && {
          exact: true,
          activeStyle,
        })}
        to={to}
        display="flex"
        lineHeight="30px"
        fontSize="16px"
        alignItems="center"
        justifyContent="space-between"
        py={1}
        px={2}
        color={isSubmenuActive ? activeStyle.color : "inherit"}
        fontWeight={isSubmenuActive ? activeStyle.fontWeight : "initial"}
        _focus={{ outline: "none" }}
        onClick={() => !haveSubmenu && handleMenuClose()}
      >
        <Text>{label}</Text>
        {haveSubmenu && <AccordionIcon />}
      </AccordionButton>

      {haveSubmenu && (
        <AccordionPanel p={1} className="space-y-1">
          {submenu.map((sm) => (
            <Box
              as={NavLink}
              key={sm.id}
              exact
              to={sm.path}
              display="block"
              fontSize={16}
              px={2}
              lineHeight="30px"
              activeStyle={activeStyle}
              _hover={{ bgColor: "#0000000a" }}
              onClick={() => handleMenuClose()}
            >
              {sm.label}
            </Box>
          ))}
        </AccordionPanel>
      )}
    </AccordionItem>
  );
};
MobileMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  submenu: PropTypes.array,
  handleMenuClose: PropTypes.func.isRequired,
};

const MobileMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const { logout, userDetails, menus } = useAuth();
  const userFullName = concatStrings(userDetails.fname, userDetails.name);

  return (
    <>
      <Box as="button" onClick={() => setOpenMenu(true)}>
        <ProfileImageBox imageUrl={userDetails.imageUrl} name={userFullName} />
      </Box>

      <Box
        position="absolute"
        top={0}
        bottom={0}
        right={0}
        left={0}
        zIndex={999}
        px={5}
        py={3}
        bgColor="#ffffff"
        className="space-y-3"
        color="#4a5568"
        display={openMenu ? "block" : "none"}
      >
        <Flex width="100%" justifyContent="end">
          <Box as="button" onClick={() => setOpenMenu(false)}>
            <IoMdCloseCircle size={28} />
          </Box>
        </Flex>

        <Flex
          justifyContent="space-between"
          alignItems="center"
          fontSize={16}
          fontWeight="normal"
          className="space-x-2"
          color="#949494"
        >
          <Box className="flex items-center space-x-3">
            <ProfileImageBox imageUrl={userDetails.imageUrl} name={userFullName} />
            <Text>{userFullName}</Text>
          </Box>

          <Box as="button" onClick={() => logout()}>
            <MdOutlineLogout size={24} color="#3a3a3a" />
          </Box>
        </Flex>

        <Accordion allowMultiple>
          {menus.map((m) => {
            const { submenu: sm } = m;
            const isSingleMenu = sm?.length == 1;
            const id = isSingleMenu ? sm[0].id : m.id;
            const path = isSingleMenu ? sm[0].path : m.path;
            const label = isSingleMenu ? sm[0].label : m.label;

            return (
              <MobileMenuLink
                key={id}
                to={path}
                label={label}
                submenu={isSingleMenu ? null : sm}
                handleMenuClose={() => setOpenMenu(false)}
              />
            );
          })}
        </Accordion>
      </Box>
    </>
  );
};

const DesktopMenu = () => {
  const { logout, userDetails, menus } = useAuth();
  const history = useHistory();

  const userFullName = concatStrings(userDetails.fname, userDetails.name);

  return (
    <>
      <UnorderedList
        listStyleType="none"
        m={0}
        p={0}
        overflow="hidden"
        className="space-x-2"
        flex={1}
      >
        {menus.map((m) => {
          const { submenu: sm } = m;
          const isSingleMenu = sm?.length == 1;
          const id = isSingleMenu ? sm[0].id : m.id;
          const path = isSingleMenu ? sm[0].path : m.path;
          const label = isSingleMenu ? sm[0].label : m.label;

          return (
            <DesktopMenuLink
              key={id}
              to={path}
              label={label}
              submenu={!isSingleMenu ? sm : undefined}
            />
          );
        })}
      </UnorderedList>
      <Menu>
        <MenuButton className="focus:outline-none">
          <Box className="flex items-center space-x-3">
            <ProfileImageBox imageUrl={userDetails.imageUrl} name={userFullName} />
            <Box className="flex space-x-1 items-center">
              <h4>{truncateString(userFullName, 15)}</h4>
              <IoIosArrowDown size={21} className="opacity-30" />
            </Box>
          </Box>
        </MenuButton>
        <MenuList>
          <MenuItem className="focus:outline-none" onClick={() => history.push("/profile")}>
            View Profile
          </MenuItem>
          <MenuItem className="focus:outline-none" onClick={() => logout()}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const { userId, menus } = useAuth();
  const navbarRef = React.useRef();
  const contentGap = 8;

  const toggleMenuUI = () => {
    const navbarChild = navbarRef.current.children;

    if (navbarChild?.length) {
      const totalWidth = navbarRef.current.clientWidth;
      const appLabelWidth = navbarChild[0].clientWidth;
      const userProfileBtnMaxWidth = 200;
      const extra = 24; // For safety
      const availableWidth =
        totalWidth - appLabelWidth - userProfileBtnMaxWidth - 2 * contentGap - extra;

      const avgMenuReqWidth = 110;
      const menuCount = menus.length;

      const collapseMenu = availableWidth <= menuCount * avgMenuReqWidth;

      setShowMobileMenu(collapseMenu);
    }
  };

  useEffect(() => {
    if (menus) {
      // In case use logged in or logged out, toggling menu UI
      toggleMenuUI();

      window.addEventListener("resize", toggleMenuUI);
    }

    return () => {
      window.removeEventListener("resize", toggleMenuUI);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menus]);

  return (
    <>
      <Flex
        ref={navbarRef}
        alignItems="center"
        justifyContent="space-between"
        gap={`${contentGap}px`}
        borderBottom="1px solid #e8e8e8"
        color="#949494"
        bg="#ffffff"
        height={12}
        px={[5, null, 10]}
      >
        <Flex alignItems="center" className="space-x-2" pr={5}>
          <img src={AppIcon} className="h-8" />
          <Text fontWeight="bold">{process.env.REACT_APP_WEBSITE_NAME}</Text>
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          justifyContent={showMobileMenu ? "end" : "space-between"}
          gap={`${contentGap}px`}
        >
          {!!(userId && menus.length) && <>{showMobileMenu ? <MobileMenu /> : <DesktopMenu />}</>}
        </Flex>
      </Flex>
    </>
  );
}

export default Navbar;
