import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { auth } from "../firebase";
import Loader from "../Components/Loader";
import useApi from "../Hooks/useApi";
import { Menus, Permissions } from "../Utils/constants";
import ProfileService from "../Services/Profile/Profile";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(); // Firebase logged in user data. Used for confirming user successfully logged in from firebase
  const [userId, setUserId] = useState(null);
  const [roleId, setRoleId] = useState("");
  const [userDetails, setUserDetails] = useState({}); // Store all user details in this state & use this variable to access user's basic details like name, imageUrl, etc.
  const [permissions, setPermissions] = useState({});
  const [menus, setMenus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoginFailed, setIsLoginFailed] = useState(false); // This state is use to handle error from the get profile API when user successfully logged from firebase but not from API server
  const [redirectPage, setRedirectPage] = useState(""); // Used to redirect user requested page after successful login
  const [isManualLogout, setIsManualLogout] = useState(false); // Used to set redirection page if user manually logged out
  const [defaultRoute, setDefaultRoute] = useState(""); // Used to set default route pages for different roles

  const { apiHelper } = useApi();

  const logout = (manuallyLoggedOut = true) => {
    setIsManualLogout(manuallyLoggedOut);

    return auth.signOut();
  };

  const getRolePermissions = (allowedPermissions) => {
    const clonedPermissions = _.cloneDeep(Permissions);
    const modules = Object.keys(allowedPermissions);

    modules.forEach((module) => {
      const modulePermissions = allowedPermissions[module];

      modulePermissions.forEach((p) => {
        if (clonedPermissions[module]) {
          clonedPermissions[module][p] = true;
        }
      });
    });

    return clonedPermissions;
  };

  const getAllowedMenus = (allowedPermissions, masterMenu) => {
    const tempMenu = [];

    for (const menu of masterMenu) {
      if (menu.submenu) {
        menu.submenu = getAllowedMenus(allowedPermissions, menu.submenu);

        if (menu.submenu.length) {
          tempMenu.push(menu);
        }
        continue;
      }

      if (!menu.permissionKey) {
        // If permissionKey is not present, it means that menu is allowed to all
        tempMenu.push(menu);
      } else {
        const { module, permission } = menu.permissionKey;

        if (allowedPermissions[module]?.includes(permission)) {
          tempMenu.push(menu);
        }
      }
    }

    return tempMenu;
  };

  // To handle error from get profile & get permissions API on login
  const handleLoginError = (e) => {
    // To prevent log out if API request cancelled
    if (e.code === "ERR_CANCELED") {
      return;
    }

    setIsLoginFailed(true);
    logout(false);
  };

  const getProfile = () => {
    apiHelper(
      ProfileService.getProfile(),
      async ({ data }) => {
        await getPermissions();
        // Setting Logged in user details
        const { id, role } = data.user;

        setUserDetails(data.user);
        setRoleId(role.id);
        setUserId(id);
      },
      false,
      (e) => {
        handleLoginError(e);
      },
    );
  };

  const getPermissions = () => {
    return apiHelper(
      ProfileService.getPermissions(),
      ({ data }) => {
        // Setting Menus & Permissions
        const menuList = getAllowedMenus(data.permissions, _.cloneDeep(Menus));
        setMenus(menuList);

        // Set default route to first menu's path
        if (menuList.length) {
          const defaultMenuId = "tasks"; // Id of menu that should be default
          let defaultMenu = menuList[0].submenu?.length ? menuList[0].submenu[0] : menuList[0]; // If default menu with above Id not allowed then we show users first allowed menu

          // Setting desired default menu
          for (const menu of menuList) {
            const { submenu, id: menuId } = menu;

            if (submenu) {
              const tempMenu = submenu.find(({ id }) => id === defaultMenuId);
              if (tempMenu) {
                defaultMenu = tempMenu;
                break;
              }
            } else if (menuId === defaultMenuId) {
              defaultMenu = menu;
              break;
            }
          }
          // Setting task route
          setDefaultRoute(defaultMenu.path);

          // After profile & permission data setup, page or menus are shown, so, setting permissions at last otherwise, error related to permission from menu or pages components may thrown.
          setPermissions(getRolePermissions(data.permissions));
        }
      },
      false,
      (e) => {
        handleLoginError(e);
      },
    );
  };

  const resetValue = () => {
    setUserId(null);
    setRoleId();
    setUserDetails({});
    setIsLoginFailed(false);
    setPermissions({});
    setMenus([]);
    setLoading(false);
    setDefaultRoute("");
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

      user ? getProfile() : resetValue();
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    currentUser && userId && Object.keys(permissions)?.length && setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, permissions]);

  const value = {
    currentUser,
    userId,
    roleId,
    userDetails,
    setUserDetails, // When user profile data updated, this will be used to update user's data all over the project like name, imageUrl...
    logout,
    isLoginFailed,
    isManualLogout,
    redirectPage,
    setRedirectPage,
    permissions,
    menus,
    defaultRoute,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loader height="100vh" spinnerSize="xl" /> : children}
    </AuthContext.Provider>
  );
}
AuthProvider.propTypes = {
  children: PropTypes.node,
};
